@import 'variable';
@media(min-width: 2500px){
  .screenshot {
    background-size: unset;
  }
}
@media(min-width: 1670px) and(max-width: 1800px) {
  .home-decor {
    .home-circle2 {
      top: 150px;
      right: 25px;
    }
    .home-circle3 {
      top: 235px;
      right: 55px;
    }
  }
  .home-right {
    width: 90%;
  }
  .team .team-circle3 {
    top: 657px;
    right: 1540px;
  }
}
@media(min-width: 1367px) and(max-width: 1669px) {
  .home-decor {
    .home-circle2 {
      top: 150px;
      right: 25px;
    }
    .home-circle3 {
      top: 235px;
      right: 55px;
    }
  }
  .home-right {
    width: 80%;
  }
  .home-two {
    background-size: unset;
  }
  .feature-phone {
    top: 110px;
    left: 185px;
    img {
      width: 50%;
    }
  }
  .features {
    text-align: center;
  }
  .vids {
    text-align: center;
  }
  .features-img {
    width: 284px;
  }
  .pc-bg {
    width: 400px;
    height: 240px;
    background-size: contain;
  }
  .img-scroll {
    left: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 57px);
    top: 11px;
  }
  .border-shape-two {
    width: 95%;
  }
  .darkHeader.theme-nav .nav-item {
    padding: 15px 0 15px 7px;
  }
  .team .team-circle3 {
    top: 678px;
    right: 1201px;
  }
  .contact-right img {
    width: 70%;
    top: 0;
  }
  .screenshot-decor .screenshot-circle3 {
    bottom: 0;
    left: 50px;
    top: auto;
  }
}
@media(min-width: 2000px) {
  .home-right {
    transform: translateY(0px);
  }
}

@media(min-width: 1500px) and (max-width: 1366px){
  .home-right {
    margin-top: -30px;
  }
}

@media(max-width: 1366px) {

  .home-right {
    width: 90%;
  }
  .back-img{
    .home-contain {
      margin-top: -60px;
    }
  }
  .home-contain {
    margin-top: 100px;
  }
  .home-two {
    background-size: auto;
    padding-bottom: 0;
    height: auto !important;
  }

  .contact-right{
    width: 70%;
  }
  .screenshot-contain {
    height: 500px;
  }
  .about {
    background-position: right;
  }
  .feature {
    background-position: bottom;
    min-height: 730px;
  }
  .feature-phone {img {
    height: 300px;

  }
  }
  .about-right {
    height: 500px;
    width: 500px;
  }

  .home-right {
    width: 65%;
    float: right;
    position: absolute;
    right: 0;
    top: -50px;
  }
  .home-decor {
    .home-circle1 {
      top: 120px;
      left: 30px;
    }
    .home-circle2 {
      top: 130px;
      right: -30px;
    }
    .home-circle3 {
      top: 200px;
      right: 25px;
    }
  }
  .feature-phone {
    top: 150px;
    left: 50px;
    img {
      width: 65%;
    }
  }
  .team-block {
    padding: 20px;
    &:hover .team-social li {
      &:nth-child(1) {
        animation: none;
      }
      &:nth-child(2) {
        animation: none;
      }
      &:nth-child(3) {
        animation: none;
      }
      &:nth-child(4) {
        animation: none;
      }
      &:nth-child(5) {
        animation: none;
      }
    }
  }
  .team {
    .team-circle1 {
      top: auto;
      left: 60px;
    }
    .team-circle2 {
      top: auto;
      left: 10px;
    }
    .team-circle3 {
      right: 0;
      left: unset;
      bottom: 90px;
    }
  }
  .screenshot {
    background-size: unset;
  }
  .screenshot-decor {
    .screenshot-circle2 {
      top: 120px;
      right: 20%;
    }
    .screenshot-circle1 {
      top: 110px;
      right: 30%;
    }
    .screenshot-circle3 {
      left: 29%;
    }
  }
  .price-type {
    padding: 10px;
  }
  .price-feature {
    margin: 20px auto;
  }
  .blog-text {
    h3 {
      margin-bottom: 10px;
    }
    h5 {
      font-size: 12px;
    }
  }
  .home-two {
    background-size: auto;
  }
  .home-three {
    background-size: cover;
  }
  .landing-caption {
    line-height: 1.5 !important;
  }
  .pc-bg {
    width: 360px;
    height: 210px;
    background-size: contain;
  }
  .img-scroll {
    width: calc(100% - 34px);
    height: calc(100% - 40px);
    left: 17px;
    top: 10px;
  }
  .border-shape-two {
    width: 95%;
  }
  .features-img {
    width: 284px;
  }
  .coming-title {
    margin-top: 70px;
  }
  .features {
    text-align: center;
    img {
      width: 100px;
    }
  }
  .features-title {
    font-size: 14px;
  }
  .tapl-title {
    font-size: 32px;
  }

  .home-style .modal.video-play .modal-dialog {
    transform: translate(-20%, 0);
  }
  .darkHeader.theme-nav .nav-item {
    padding: 20px 0;
  }
  .home-contain p {
    line-height: 22px;
  }
  .feature-decor .feature-circle1 {
    top: auto;
  }
  .about-decor .about-circle1 {
    right: 0;
  }
  .price-block .btn-custom {
    padding: 11px 25px;
  }
  .testimonial-carousel.owl-carousel button {
    &.owl-prev {
      left: -80px;
    }
    &.owl-next {
      right: -80px;
    }
  }
  .footer-form .form-control {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .footer-text h2 {
    margin: 15px auto;
  }
  iframe.video-play {
    width: 700px;
    height: 600px;
  }
  .animated-circle.three {
    img {
      bottom: 50px;
    }
    &:before {
      bottom: 68px;
    }
    &:after {
      bottom: 68px;
    }
  }
  img.main-logo {
    width: 100%;
  }
  .landing-circle3 img {
    top: 0;
    left: 14px;
  }
  .landing-circle2 img {
    top: 160px;
    left: 125px;
  }
  .landing-circle1 img {
    top: 10px;
    left: 200px;
  }
}
@media(max-width: 1200px) {
  img.main-logo {
    width: unset;
  }
  .home-contain {
    margin-top: 0;
  }
}
@media(max-width: 1199px) {
  .coming-soon{
    .innerpage-decor{
      opacity: 0.1;
    }
  }
  .mob-mocup {
    width: 248px;
    left: 355px;
  }
  .home-three .contain {
    left: calc(50% + 10px);
  }
  .footer-style {
    li {
      + li {
        margin-top: 30px;
      }
    }
  }
  h1 {
    font-size: 46px;
  }
  h2 {
    font-size: 26px;
  }
  h4 {
    font-size: 14px;
  }
  section{
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .testimonial-text{
    margin-bottom: 80px;
  }
  .about-text{
    h3{
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .footer{
    padding-top: 80px;
  }
  .team{
    .team-circle3{
      display: none;
    }
  }
  .home-right {
    transform: translateY(70px) !important;
  }
  .home-decor {
    .home-circle1 {
      top: 125px;
      left: 65px;
    }
    .home-circle2 {
      right: -30px;
    }
    .home-circle3 {
      top: 211px;
      right: 5px;
    }
  }
  .feature-phone {
    top: 126px;
    left: 75px;
  }
  .top-margin {
    margin-top: 20px;
  }
  .mobile-light-left {
    left: 35%;
  }
  .mobile-light-right {
    right: 35%;
  }
  @-webkit-keyframes highlight-left {
    0% {
      opacity: 0;
      top: 320px;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      top: -120px;
    }
  }
  @keyframes highlight-left {
    0% {
      opacity: 0;
      top: 320px;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      top: -120px;
    }
  }
  @-webkit-keyframes highlight-right {
    0% {
      opacity: 0;
      bottom: 0;
      transform: scale(0);
    }
    70% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      top: 320px;
      transform: scale(0);
    }
  }
  @keyframes highlight-right {
    0% {
      opacity: 0;
      bottom: 0;
      transform: scale(0);
    }
    70% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      top: 320px;
      transform: scale(0);
    }
  }
  .screenshot-decor {
    .screenshot-circle1 {
      top: 30px;
      right: 25%;
    }
    .screenshot-circle2 {
      top: 70px;
      right: 13%;
    }
    .screenshot-circle3 {
      left: 19%;
      bottom: 0;
    }
  }
  .team {
    .team-circle1 {
      top: auto;
      right: -70px;
      left: auto;
    }
    .team-circle2 {
      left: 0;
    }
  }
  .team-box {
    padding: 15px;
  }
  .blog-text {
    h3 {
      margin-bottom: 10px;
    }
    h6 {
      margin-top: 15px;
    }
    h5 {
      margin: 0;
      line-height: 20px;
    }
  }
  .price-item {
    padding-top: 0;
    padding-bottom: 0;
  }
  .address-bar {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .contact-decor {
    .contact-circle2 {
      top: auto;
    }
    .contact-circle1 {
      position: absolute;
      top: auto;
      left: 280px;
    }
  }
  .testimonial-decor {
    .testi-circle1 {
      left: 0;
      top: 10%;
    }
    .testi-circle2 {
      left: auto;
      top: auto;
      bottom: 0;
    }
  }
  .index-footer {
    margin: 0 auto;
    position: absolute;
    width: 100%;
  }
  .footer-text {
    padding-top: 0;
  }
  .sidebar-space {
    margin-bottom: 30px;
  }
  .marg-20 {
    margin: 10px auto;
  }
  .blog-block {
    padding: 5px;
  }
  .home-two {
    background-size: cover;
    background-position: 70%;
  }
  .coming-title {
    font-size: 28px;
  }
  .margin-top-bottom {
    margin-top: 70px;
  }
  .tapl-title {
    font-size: 28px;
  }
  .features-img {
    width: 230px;
  }
  .theme-nav .nav-item {
    font-size: 13px;
    padding: 30px 0;
  }
  .home-contain h4 {
    font-size: 14px;
  }
  .about-text p {
    line-height: 1.5;
  }
  .about-style li {
    margin-bottom: 15px;
    min-height: 50px;
  }
  .team-social li {
    margin: 0 2px;
  }
  .team-block .team-social {
    opacity: 1;
    li a {
      padding: 5px;
    }
  }
  .blog-pagin a.page-link {
    font-size: 14px;
  }
  .contact-right img {
    width: 85%;
  }
  .testimonial-text h6 {
    margin-bottom: 0;
  }
  .blog-detail .blog-text h5 {
    font-size: 14px;
  }
  .breadcrumb-bg h2 {
    font-size: 20px;
  }
  .blog-list > div + div + div .blog-item {
    margin-top: 40px;
  }
  .blog-cat-detail ul li i {
    margin-right: 5px;
  }
  .pages-space > div + div + div {
    margin-top: 50px;
  }
  @keyframes movebounce {
    0% {
      transform: translateX(0px); }
    50% {
      transform: translateX(20px); }
    100% {
      transform: translateX(0px); } }
}
@media(max-width: 1024px) {
  .feature-phone {
    top: 100px;
  }
  .about {
    background-size: contain;
  }
  .about-decor {
    .about-circle1 {
      top: 0;
    }
    .about-circle2 {
      bottom: 0;
      right: -150px;
    }
  }
  .feature-phone {
    left: 0;
    top: unset;
  }
  .testimonial-text {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    p {
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 1.5;
    }
  }
  .mobile-light-left {
    left: 35%;
  }
  .mobile-light-right {
    right: 35%;
  }
  .contact {
    background-size: cover;
  }
  @-webkit-keyframes highlight-left {
    0% {
      opacity: 0;
      top: 330px;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      top: -120px;
    }
  }
  @keyframes highlight-left {
    0% {
      opacity: 0;
      top: 330px;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      top: -120px;
    }
  }
  @-webkit-keyframes highlight-right {
    0% {
      opacity: 0;
      bottom: 100px;
      transform: scale(0);
    }
    70% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      top: 330px;
      transform: scale(0);
    }
  }
  @keyframes highlight-right {
    0% {
      opacity: 0;
      bottom: 0;
      transform: scale(0);
    }
    70% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      top: 330px;
      transform: scale(0);
    }
  }
  .index-footer {
    position: relative;
  }
  .home-two {
    background-position: 62%;
  }
  .pc-bg {
    width: 300px;
    height: 180px;
  }
  .img-scroll {
    left: 15px;
    top: 9px;
    width: calc(100% - 29px);
    height: calc(100% - 38px);
  }
  .template-bg {
    height: auto;
  }
  .home-decor .home-circle1 {
    top: auto;
    left: 65px;
    bottom: 0;
  }
  .contact-decor .contact-circle2 {
    top: auto;
    bottom: 0;
    left: 10%;
  }
  .features li {
    padding: 40px 0;
  }
}
@media(max-width: 992px) {
  .feature{
    .feature-style {
      li{
        &:last-child {
          margin-bottom: 30px;
        }
      }
    }
    .sm-m-top{
      .feature-style {
        li{
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .testimonial {
    background-size: cover;
  }
  .footer-text {
    margin-top: 0;
  }
  .feature-phone {
    top: 100px;
  }
  .feature-decor .feature-circle1 {
    display: none;
  }
  .team .team-circle3 {
    display: none;
  }
  .contact-right img {
    width: 67%;
  }
  section *{
    animation: none !important;
  }
  .mobile-light-right,.mobile-light-left{
    display: none;
  }
  .d-align-center {
    display: flex;
    align-items: center;
  }
}
@media(max-width: 991px) {
  .not-found{
    .innerpage-decor{
      display: none;
    }
    .error-font {
      font-size: 180px;
    }
  }
  .mob-mocup {
    width: 245px;
    left: 237px;
  }
  .home-three .contain {
    left: calc(50% + 0px);
  }
  .home-two {
    .contain {
      top: calc(50% + 5px);
    }
  }
  .home-style {
    nav{
      &.navbar {
        .navbar-nav {
          .active {
            > .nav-link {
              color: #17f3d4;
            }
          }
        }
      }
    }
  }
  .about-right {
    height: 350px;
    width: 350px;
  }
  .feature {
    min-height: auto;
  }
  .team {
    background-position: bottom;
  }
  #navbarSupportedContent {
    background: $white;
    .nav-item {
      padding: 0;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  .darkHeader {
    padding: 0.5rem 1rem;
    &.navbar-light .navbar-nav .nav-link.active {
      color: $black !important;
    }
  }
  .feature {
    background-size: auto;
  }
  .feature-style {
    li {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    p {
      margin: 0 auto;
      line-height: 20px;
    }
  }
  .price {
    background-size: contain;
    background-position: top;
  }
  .contact {
    background-position: center;
  }
  .footer {
    padding-bottom: 0 !important;
  }
  .mrp {
    h2 {
      font-size: 28px;
      margin-top: 20px;
    }
    h6.user-type {
      margin: 20px;
    }
  }
  .price-year {
    margin-bottom: 20px;
  }
  .md-margin-top {
    margin-top: 20px !important;
  }
  h2 {
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 25px;
    &.title {
      margin-top: 0;
      line-height: 1;
      text-align: left;
    }
  }
  h1 {
    font-size: 40px;
    margin: 20px auto;
  }
  h4 {
    font-size: 13px;
  }
  p {
    font-size: 13px;
    line-height: 20px;
    &.caption-about {
      text-align: left;
    }
    &.slide-cap-desc {
      font-size: 14px;
    }
  }
  .home-right {
    width: 100%;
    transform: translateY(0px);
  }
  .about-decor {
    .about-circle1 {
      left: auto;
      right: -50px;
      top: auto;
    }
    .about-circle2 {
      right: -50px;
    }
  }
  .screenshot-decor {
    .screenshot-circle1 {
      right: 23%;
    }
    .screenshot-circle2 {
      right: 15%;
    }
  }
  .feature-phone {
    display: none;
  }
  .d-medium-none-lg {
    display: none;
  }
  .mobile-light-left {
    left: 30%;
  }
  .mobile-light-right {
    right: 30%;
  }
  @-webkit-keyframes highlight-left {
    0% {
      opacity: 0;
      top: 350px;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      top: -140px;
    }
  }
  @keyframes highlight-left {
    0% {
      opacity: 0;
      top: 350px;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      top: -140px;
    }
  }
  @-webkit-keyframes highlight-right {
    0% {
      opacity: 0;
      bottom: 0;
      transform: scale(0);
    }
    70% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      top: 350px;
      transform: scale(0);
    }
  }
  @keyframes highlight-right {
    0% {
      opacity: 0;
      bottom: 0;
      transform: scale(0);
    }
    70% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      top: 350px;
      transform: scale(0);
    }
  }
  .team {
    .team-circle2 {
      top: auto;
      left: -10px;
      bottom: 150px;
    }
    .team-circle3 {
      top: 50%;
      left: auto;
      right: 0;
    }
  }
  .blog-page {
    section {
      padding-bottom: 50px;
      padding-top: 50px;
    }
  }
  .leave-coment {
    margin-top: 0;
  }
  .blog-text {
    h5 {
      line-height: 1.6;
    }
  }
  .md-height {
    height: 100%;
  }
  .page-margin {
    margin-top: 100px;
  }
  .d-align-center {
    display: flex;
  }
  .reply-comment {
    > div + div {
      margin-top: 20px;
    }
  }
  .details-sidebar {
    margin-top: 100px;
  }
  .home-two {
    background-position: 30%;
    background-size: cover;
  }
  .navbarSupportedContent {
    color: $color_tapa;
  }
  .home-style-two {
    .navbar-light .navbar-nav .nav-link {
      color: $color_tapa;
    }
    .darkHeader .navbar-nav .active > .nav-link {
      color: $color_bright_turquoise;
    }
  }
  .img-scroll {
    left: 19px;
    top: 11px;
    width: calc(100% - 38px);
    height: calc(100% - 39px);
  }
  .pc-bg {
    width: 400px;
    height: 230px;
  }
  .mt-70 {
    text-align: center;
  }
  .blog-detail {
    .md-fgrup-margin {
      margin-bottom: 25px;
    }
    .blog-text h3 {
      font-size: 16px;
    }
  }
  .home .container {
    margin-top: 0 !important;
  }
  .nav-item.dropdown .dropdown-menu {
    box-shadow: none;
    padding: 0 0 0 15px;
  }
  .dropdown-menu .nav-item {
    margin-left: 0;
  }
  #mymenu .dropdown-menu {
    display: none;
    transition: 0.5s ease;
    &.show {
      display: block;
      transition: 0.5s ease;
    }
    .nav-item {
      margin-left: 0;
    }
  }
  .navbar-light .navbar-nav .nav-link {
    color: $color_tapa;
  }
  .price-feature li {
    padding: 5px;
  }
  .about-text p {
    padding-left: 5px;
  }
  .about-style li {
    margin-bottom: 20px;
  }
  .feature-subtitle h3 {
    margin-top: 0;
  }
  .feature-decor .feature-circle1 {
    top: 570px;
    right: 13px;
  }
  .team-social li {
    margin: 0 3px;
  }
  .team-block .team-social li a {
    padding: 7px;
  }
  .team-carousel .owl-dots {
    position: relative;
    top: 10px;
  }
  .blog-carousel .owl-dots {
    position: relative;
    top: 10px;
  }
  .price-carousel .owl-dots {
    position: relative;
    top: 10px;
  }
  .price-block:hover {
    transform: unset;
  }
  .contact-right img {
    top: 0;
  }
  .blog-sec .blog-list > div + div .blog-item {
    margin-top: 40px;
  }
  .blog-description p {
    font-size: 14px;
  }
  .testimonial-carousel .owl-dots {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
  }
  .home-style nav {
    &.navbar .navbar-nav .nav-link {
      color: $color_tapa;
    }
    &.darkHeader .navbar-nav .active > .nav-link {
      color: $color_bright_turquoise;
    }
  }
  .features img {
    width: 80px;
  }
  .mt-35 > div + div {
    margin-top: 70px;
  }

  /*404 page*/
  .error-wrapper .error-heading .cloud-second{
    margin-top: -100px;
    .cloud-first {
      width: 700px;
    }
    .headline {
      font-size: 235px;
    }
  }
  .margin-bottom > div + div + div .review-box {
    margin-top: 30px;
  }
}
@media(max-width: 768px) {
  body{
    overflow-x: hidden;
  }
  
  .full-page{
    overflow: auto;
  }
  .home{
    min-height: 100vh;
  }
  .mobile-light-right {
    top: -150px;
  }
  @-webkit-keyframes highlight-left {
    0% {
      opacity: 0;
      top: 330px;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      top: -90px;
    }
  }
  @keyframes highlight-left {
    0% {
      opacity: 0;
      top: 330px;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      top: -90px;
    }
  }
  @-webkit-keyframes highlight-right {
    0% {
      opacity: 0;
      bottom: 0;
      transform: scale(0);
    }
    70% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      top: 490px;
      transform: scale(0);
    }
  }
  @keyframes highlight-right {
    0% {
      opacity: 0;
      bottom: 0;
      transform: scale(0);
    }
    70% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      top: 320px;
      transform: scale(0);
    }
  }
  .testimonial-block {
    padding-top: 80px;
    padding-bottom: 30px;
  }
  .home-two {
    background-position: top;
    background-color: #19d6dc;
  }
  .features-img {
    width: 250px;
  }
  .screenshot-decor .screenshot-circle3 {
    left: 0;
    bottom: 0;
  }
  .testimonial-carousel.owl-carousel button {
    &.owl-prev {
      left: -20px;
    }
    &.owl-next {
      right: -20px;
    }
  }
  .footer-icon img {
    width: 60%;
    margin-left: 8px;
  }
  .features li {
    &:nth-child(even) {
      border-right: none;
    }
    &:nth-child(13) {
      border-bottom: 1px solid $color_alto;
    }
    &:nth-child(14) {
      border-bottom: 1px solid $color_alto;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .error-wrapper {
    .error-heading {
      margin-top: 100px;
      .cloud-first {
        width: 100%;
        margin-top: 0px;
      }
      .headline {
        font-size: 235px;
        margin-top: -130px;
      }
      .cloud-second{
        margin-top: -90px;
      }
    }
    .sub-content{
      margin-top: 100px;
    }
  }
  /*Sign up*/
  .theme-form .form-control{
    box-shadow: 0 0 5px 0 rgb(24, 231, 211);
  }
  .theme-form .form-control{
    box-shadow:0 0 5px 0 rgb(24, 231, 211);
  }
}
@media(max-width: 767px) {
  .faq{
    .card-body{
      font-size: 13px;
    }
  }
  .mob-mocup {
    width: 280px;
    left: 128px;
  }
  .testimonial-carousel {
    .owl-dots {
      bottom: 60px;
    }
  }
  .home-three {
    background-color: #19d8db;
  }
  .home-three {
    .contain{
      position: relative;
      top: unset;
      left: unset;
      margin-right: auto;
      transform: none;
    }
  }
  .animated-circle{
    &.three {
      img {
        left: 0;
      }
    }
  }
  .home-two{
    .contain{
      position: relative;
      margin-right: auto;
      top: auto;
      left: auto;
      transform: none;
    }
  }
  .animated-circle{
    &:after {
      animation: none;
      box-shadow: none;
    }
  }
  .home-contain {
    margin-top: 0;
  }
  .leave-coment{
    h3{
      &.text-center{
        text-align: left !important;
      }
    }
    .footer-form{
      input::-webkit-input-placeholder {
        text-align: left;
      }
      textarea::-webkit-input-placeholder {
        text-align: left;
      }
      .form-control{
        text-align: left;
      }
    }
  }
  .garb-img {
    text-align: center;
    margin-top: 30px;
  }
  .price-type {
    margin: 0 auto;
  }
  .testimonial {
    background-size: contain;
  }
  .testimonial-avtar {
    height: 100px;
    width: 100px;
    &:before {
      height: 100px;
      width: 100px;
    }
    &:after {
      height: 100px;
      width: 100px;
    }
    img {
      height: 70px;
    }
  }
  .testimonial-text {
    padding: 60px 20px 20px;
    p {
      font-size: 14px;
    }
    h3 {
      line-height: 1;
      margin-top: 20px;
    }
  }
  .d-medium-none-lg {
    display: none;
  }
  .price-block {
    padding: 30px;
    .btn-custom {
      width: 50%;
    }
  }
  .breadcrumb-bg {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .d-medium-none {
    display: none;
  }
  .mobile-light-left {
    left: 20%;
  }
  .mobile-light-right {
    right: 21%;
  }
  .home-right {
    display: none;
  }
  .home-contain {
    text-align: center;
    > div {
      width: 100%;
    }
  }
  .home-decor {
    .home-circle2 {
      right: -76px;
    }
    .home-circle3 {
      top: 211px;
      right: -47px;
    }
  }
  .about-phone {
    display: none;
  }
  .index-footer {
    bottom: unset;
    padding-bottom: 0;
  }
  .md-fgrup-margin {
    margin-bottom: 25px;
  }
  .blog-bradcrumb {
    float: unset;
    padding-bottom: 5px;
    .breadcrumb {
      justify-content: center;
      align-items: center;
      padding: 10px;
      padding-bottom: 0;
    }
    li {
      line-height: 1.5;
      a {
        line-height: 1.5;
      }
    }
  }
  .col-text-center {
    text-align: center;
  }
  .d-align-center {
    justify-content: center;
  }
  .blog-page{
    &.blog-detail {
      .media {h5 {
        max-width: 100%;
      }
      }
    }
  }
  .blog-cat-detail {
    .marg-15 {
      margin: 10px auto;
    }
    ul li a {
      font-size: 14px;
    }
  }
  @-webkit-keyframes highlight-left {
    0% {
      opacity: 0;
      top: 380px;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      top: -120px;
    }
  }
  @keyframes highlight-left {
    0% {
      opacity: 0;
      top: 380px;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      top: -120px;
    }
  }
  @-webkit-keyframes highlight-right {
    0% {
      opacity: 0;
      bottom: 0;
      transform: scale(0);
    }
    70% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      top: 370px;
      transform: scale(0);
    }
  }
  @keyframes highlight-right {
    0% {
      opacity: 0;
      bottom: 0;
      transform: scale(0);
    }
    70% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      top: 370px;
      transform: scale(0);
    }
  }
  .team-block {
    padding: 30px;
  }
  .md-height {
    height: 200px;
  }
  .list-sidebar {
    margin-top: 50px;
  }
  .mb-xs-4 {
    margin-bottom: 1.5rem;
  }
  .animated-circle {
    &:before {
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 40px;
      height: 40px;
      bottom: 50px;
    }
    &:after {
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 50px;
    }
    &.three {
      &:before {
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      &:after {
        display: none;
      }
    }
  }
  @keyframes anim-effect-back {
    0% {
      box-shadow: 0 0 0 20px $color_bon_jour;
      opacity: 1;
    }
    50% {
      box-shadow: 0 0 0 50px $color_bon_jour;
      opacity: 1;
    }
    100% {
      box-shadow: 0 0 0 70px $color_bon_jour;
      opacity: 1;
    }
  }
  @keyframes anim-effect-front {
    0% {
      box-shadow: 0 0 0 15px $color_bon_jour;
      opacity: 1;
    }
    50% {
      box-shadow: 0 0 0 30px $color_bon_jour_60;
      opacity: 1;
    }
    100% {
      box-shadow: 0 0 0 40px rgba(224, 224, 224, 0);
      opacity: 0;
    }
  }
  .margin-top-bottom {
    margin-top: 50px;
  }
  .demo-description {
    line-height: 20px;
  }
  .sm-d-none {
    display: none;
  }
  .coming-title {
    font-size: 22px;
  }
  .landing-title {
    margin-bottom: 0;
  }
  .border-shape-two {
    margin: 20px auto 10px;
  }
  .tapl-title {
    font-size: 24px;
  }
  .center-sm {
    text-align: center;
  }
  .price-feature li {
    padding: 5px;
  }
  .about-style li {
    margin-bottom: 30px;
  }
  .about-text p {
    text-align: left;
  }
  .testimonial-decor .testi-circle2 {
    bottom: 0;
  }
  .testimonial-carousel.owl-carousel button {
    &.owl-prev {
      left: -40px;
    }
    &.owl-next {
      right: -40px;
    }
  }
  .about-contain > div {
    width: 100%;
    text-align: center;
  }
  .feature-decor .feature-circle1 {
    display: none;
  }
  .team .team-circle3 {
    display: none;
  }
  .blog-list > div + div .blog-item {
    margin-top: 40px;
  }
  .blog-text h6 {
    margin-top: 15px;
  }
  .blog-page .media {
    h5 {
      font-size: 13px;
    }
  }
  .video-play iframe {
    height: 280px;
  }
  .play-button img {
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 30px;
    width: 80px;
  }
  .mt-35 > div + div {
    margin-top: 40px;
  }
  .testimonial-avtar {
    &:after {
      display: none;
    }
    &:before{
      display: none;
    }
  }
  .margin-bottom > div + div + div .review-box {
    margin-top: 30px;
  }
  .margin-bottom > div + div .review-box {
    margin-top: 30px;
  }
}

@media(max-width: 600px) {
  .margin-bottom > div .review-box {
    padding: 20px 15px;
  }
  .testimonial-text {
    box-shadow: 0 0 10px 0 rgba(170, 170, 170, 0.25);
  }
  .landing-circle{
    display: none;
  }
  .reply-comment {
    .media {
      img {
        width: 70px;
      }
    }
  }
  .sm-mb {
    margin-bottom: 15px;
  }
  .home-decor {
    .home-circle3 {
      display: none;
    }
    .home-circle2 {
      right: -56px;
    }
  }
  .about-phone {
    display: none;
  }
  .team {
    .team-circle1 {
      display: none;
    }
    .team-circle2 {
      display: none;
    }
    .team-circle3 {
      display: none;
    }
  }
  .list-sidebar {
    margin-top: 50px;
  }

  @-o-keyframes anim-effct-testi {
    0% {
      box-shadow: 0 0 0 8px $color_bon_jour;
      opacity: 1;
    }
    50% {
      box-shadow: 0 0 0 15px $color_bon_jour_60;
      opacity: 1;
    }
    100% {
      box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
      opacity: 0;
    }
  }
  @-moz-keyframes anim-effct-testi {
    0% {
      box-shadow: 0 0 0 8px $color_bon_jour;
      opacity: 1;
    }
    50% {
      box-shadow: 0 0 0 15px $color_bon_jour_60;
      opacity: 1;
    }
    100% {
      box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
      opacity: 0;
    }
  }
  @-webkit-keyframes anim-effct-testi {
    0% {
      box-shadow: 0 0 0 8px $color_bon_jour;
      opacity: 1;
    }
    50% {
      box-shadow: 0 0 0 15px $color_bon_jour_60;
      opacity: 1;
    }
    100% {
      box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
      opacity: 0;
    }
  }
  @keyframes anim-effct-testi {
    0% {
      box-shadow: 0 0 0 8px $color_bon_jour;
      opacity: 1;
    }
    50% {
      box-shadow: 0 0 0 15px $color_bon_jour_60;
      opacity: 1;
    }
    100% {
      box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
      opacity: 0;
    }
  }
  @-o-keyframes anim-effct-testi-back {
    0% {
      box-shadow: 0 0 0 8px $color_bon_jour;
      opacity: 1;
    }
    50% {
      box-shadow: 0 0 0 15px $color_bon_jour;
      opacity: 1;
    }
    100% {
      box-shadow: 0 0 0 25px $color_bon_jour;
      opacity: 1;
    }
  }
  @-moz-keyframes anim-effct-testi-back {
    0% {
      box-shadow: 0 0 0 8px $color_bon_jour;
      opacity: 1;
    }
    50% {
      box-shadow: 0 0 0 15px $color_bon_jour;
      opacity: 1;
    }
    100% {
      box-shadow: 0 0 0 25px $color_bon_jour;
      opacity: 1;
    }
  }
  @-webkit-keyframes anim-effct-testi-back {
    0% {
      box-shadow: 0 0 0 8px $color_bon_jour;
      opacity: 1;
    }
    50% {
      box-shadow: 0 0 0 15px $color_bon_jour;
      opacity: 1;
    }
    100% {
      box-shadow: 0 0 0 25px $color_bon_jour;
      opacity: 1;
    }
  }
  @keyframes anim-effct-testi-back {
    0% {
      box-shadow: 0 0 0 8px $color_bon_jour;
      opacity: 1;
    }
    50% {
      box-shadow: 0 0 0 15px $color_bon_jour;
      opacity: 1;
    }
    100% {
      box-shadow: 0 0 0 25px $color_bon_jour;
      opacity: 1;
    }
  }
  .home-style-two .modal.video-play .modal-dialog {
    transform: translate(0%, 0);
  }
  .home-style .modal.video-play .modal-dialog {
    transform: translate(0%, 0);
  }
  .video-play iframe {
    height: 280px;
    width: 480px;
  }
  .about-style li {
    margin-bottom: 25px;
  }
  p.caption-about {
    margin-bottom: 22px;
  }
  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 17px !important;
  }
  .price-carousel .owl-dots {
    margin-top: 17px !important;
  }
  .about-decor .about-circle2 {
    display: none;
  }
  .landing-circle2 img {
    display: none;
  }
  .about-contain > div {
    width: 100%;
    text-align: center;
  }
  .blog-text h3 {
    font-size: 14px;
  }
  .footer-text h2 {
    margin: 20px;
  }
  .animated-circle.three {
    img {
      bottom: 20px;
    }
    &:before {
      bottom: 38px;
    }
    &:after {
      bottom: 38px;
    }
  }
}
@media(max-width: 576px) {
  .clock-box li {
    width: 50px;
    margin: 0px 5px 20px;
    span{
      font-size: 22px;
      width: 50px;
      height: 50px;
    }
  }
  .authentication-form{
    .theme-form {
      .form-control {
        padding: 12px 20px;
      }
    }
  }
  .not-found{
    .error-font {
      font-size: 120px;
      letter-spacing: -22px;
    }
    .mt-5{
      margin-top: 35px !important;
    }
  }
  .download{
    .innerpage-decor{
      display: none;
    }
    .downlod-img {
      margin-bottom: 20px;
      height: 125px;
    }
    h3{
      margin-top: 0;
    }
    padding-bottom: 0;
    min-height: auto;
  }
  .authentication-form{
    .social-btns{
      .fb{
        margin-bottom: 10px;
      }
    }
    .card{
      padding: 15px;
      width: 290px;
    }
    p{
      width: 90%;
      margin-bottom: 15px;
    }
  }
  .testimonial-block {
    margin-top: -20px;
  }
  .testimonial-carousel {
    .owl-dots {
      bottom: 30px;
    }
  }
  .mob-mocup {
    display: none;
  }
  .screenshot-item {
    border: 2px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
  }
  section ,.address-bar{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .testimonial-text{
    margin-bottom: 50px;
  }
  .footer{
    padding-top: 50px;
  }
  .marg-20 {
    margin: 7px auto;
  }
  .mb-xs-4 {
    margin-bottom: 15px;
  }
  @-webkit-keyframes highlight-left {
    0% {
      opacity: 0;
      top: 370px;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      top: -120px;
    }
  }
  @keyframes highlight-left {
    0% {
      opacity: 0;
      top: 370px;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      top: -120px;
    }
  }
  @-webkit-keyframes highlight-right {
    0% {
      opacity: 0;
      bottom: 0;
      transform: scale(0);
    }
    70% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      top: 350px;
      transform: scale(0);
    }
  }
  @keyframes highlight-right {
    0% {
      opacity: 0;
      bottom: 0;
      transform: scale(0);
    }
    70% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      top: 350px;
      transform: scale(0);
    }
  }
  .features-img {
    width: 214px;
  }
  .coming-title {
    font-size: 18px;
    line-height: 1.5;
  }
  .tapl-title {
    font-size: 18px;
  }
  .rating-star {
    img {
      margin-right: 15px;
      width: 40px;
    }
  }
  h2.title {
    letter-spacing: 2px;
  }
  .feature h2 {
    font-size: 20px;
  }
  .home-contain p {
    line-height: 18px;
    padding: 0 15px;
  }
  .footer-text p {
    line-height: 18px;
  }
  .blog-list > div + div .blog-item {
    margin-top: 40px;
  }
  .theme-form .form-group {
    margin: 15px auto;
  }
  .video-play iframe {
    height: 280px;
  }
  section{
    .about-contain {
      animation: none;
    }
    .about-phone {
      animation: none;
    }
    .feature-phone {
      animation: none;
    }
    .screenshot-contain {
      animation: none;
    }
    .price-block {
      animation: none;
    }
    .contact-right {
      animation: none;
    }
    .contact-us {
      animation: none;
    }
    .footer-text {
      animation: none;
    }
    .contact-subscribe {
      animation: none;
    }
    .feature-style li {
      &:nth-child(1) {
        animation: none;
      }
      &:nth-child(2) {
        animation: none;
      }
      &:nth-child(3) {
        animation: none;
      }
    }
    .team-carousel .team-item {
      animation: none;
    }
    .blog-carousel .blog-item {
      animation: none;
    }
    .testimonial-carousel .testimonial-item {
      animation: none;
    }
    .bottom-section .footer-style {
      animation: none;
    }
    .map iframe {
      animation: none;
    }
  }
  .team-block:hover .team-social li {
    &:nth-child(1) {
      animation: none;
    }
    &:nth-child(2) {
      animation: none;
    }
    &:nth-child(3) {
      animation: none;
    }
    &:nth-child(4) {
      animation: none;
    }
    &:nth-child(5) {
      animation: none;
    }
  }
}
@media(max-width: 575.98px) {
  .about-style li {
    min-height: auto;
  }
  .screenshot {
    background-size: cover;
  }
  .home-style-two .modal.video-play .modal-dialog {
    transform: translate(0%, 0);
  }
  .home-style .modal.video-play .modal-dialog {
    transform: translate(0%, 0);
  }
}
@media(max-width: 480px) {
  .testimonial-carousel{
    &.owl-theme {
      .owl-nav{
        &.disabled + .owl-dots{
          position: absolute;
          top: 80%;
        }
      }
    }
  }
  .home-two {
    background-position: 35%;
    background-size: cover;
  }
  .mobile-light-right {
    right: 20%;
  }
  @-webkit-keyframes highlight-left {
    0% {
      opacity: 0;
      top: 310px;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      top: -100px;
    }
  }
  @keyframes highlight-left {
    0% {
      opacity: 0;
      top: 310px;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      top: -100px;
    }
  }
  @-webkit-keyframes highlight-right {
    0% {
      opacity: 0;
      bottom: 0;
      transform: scale(0);
    }
    70% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      top: 300px;
      transform: scale(0);
    }
  }
  @keyframes highlight-right {
    0% {
      opacity: 0;
      bottom: 0;
      transform: scale(0);
    }
    70% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      top: 300px;
      transform: scale(0);
    }
  }
  .pc-bg {
    width: 270px;
    height: 150px;
  }
  .img-scroll {
    left: 12px;
    top: 7px;
    width: calc(100% - 29px);
    height: calc(100% - 23px);
  }
  .landing-caption {
    line-height: 1.2 !important;
  }
  .testimonial-block {
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .feature-subtitle h3 {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 0;
  }
  .feature-style p {
    line-height: 18px;
  }
  .team-carousel .owl-dots {
    top: -54px;
  }
  .breadcrumb-bg h2 {
    font-size: 18px;
  }
  .blog-sec .blog-list > div + div .blog-item {
    margin-top: 30px;
  }
  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 15px;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: auto;
  }
  .price-block .btn-custom {
    width: auto;
  }
  .video-play iframe {
    height: 250px;
    width: auto;
  }
  img.main-logo {
    width: 60%;
  }
  .features li {
    padding: 20px 0;
    &:nth-child(15) {
      border-bottom: 1px solid $color_alto;
    }
  }
  .testimonial-text p {
    font-size: 12px;
  }
  .testimonial-carousel.owl-carousel button {
    &.owl-prev {
      left: 0;
      position: relative;
    }
    &.owl-next {
      right: 0;
      position: relative;
    }
  }
  .coming-soon {
    .footer-text{
      .footer-form{
        .form-control{
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }
  }

  @media(max-width: 479.5px) {
    .features li {
      border-right: none;
    }
  }
  @media(max-width: 400px) {
    h1 {
      font-size: 34px;
    }
    .feature-subtitle {
      margin-bottom: 5px;
    }
    .mobile-light-left {
      display: none;
    }
    .mobile-light-right {
      display: none;
    }
    .testi-circle1 {
      display: none;
    }
    .testi-circle2 {
      display: none;
    }
    .coming-title {
      margin-top: 50px;
    }
    .tap-top {
      width: 40px;
      height: 40px;
      line-height: 1.7;
    }
    .feature-style p {
      font-size: 12px;
    }
    .testimonial-carousel.owl-carousel button {
      &.owl-next {
        display: none;
      }
      &.owl-prev {
        display: none;
      }
    }
    .team-block .team-social li a {
      padding: 5px;
    }
    .video-play iframe {
      height: 200px;
    }
    .footer-text h2 {
      margin: 10px auto;
      font-size: 17px;
    }
    .features li {
      border-right: none;
    }
    .mt-35 > div + div {
      margin-top: 50px;
    }
    .rating-star img {
      margin-right: 10px;
      width: 20px;
    }
  }
  @media(max-width: 360px) {
    .blog-list > div + div .blog-item {
      margin-top: 30px;
    }
    .blog-page{
      &.blog-detail {
        .media {
          h5 {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          p{
            font-size: 13px;
          }
        }
      }
    }

    .feature-subtitle {
      h3 {
        line-height: 15px;
      }
      p {
        font-size: 9px;
      }
    }
    .home-decor {
      .home-circle1 {
        left: 50px;
      }
      .home-circle2 {
        right: -62px;
        top: 100px;
      }
    }
    .marg-20 {
      margin: 5px auto;
    }
    .blog-text {
      h6 {
        margin-top: 10px;
        font-size: 11px;
      }
      h5 {
        font-size: 11px;
      }
      h3.blog-head {
        font-size: 14px;
      }
    }
    .testimonial-text {
      h3 {
        font-size: 15px;
        margin-top: 0;
      }
      h6 {
        line-height: 2;
      }
    }
    .team-block {
      padding: 5px;
    }
    .blog-sec .blog-list > div + div .blog-item {
      margin-top: 30px;
    }
    .recent-blog .media p {
      font-size: 11px;
    }
  }

  @media(max-width: 340px) {
    .footer-form {
      padding: 0;
    }
    .team-social li {
      margin: 0 2px;
    }
    .footer-address a {
      padding-left: 54px;
      line-height: 18px;
    }
    .footer-icon img {
      width: 30%;
      margin-left: 30px;
    }
  }
  @media(max-width: 320px) {
    .home-three{
      .play-button{
        margin-top: 70px;
      }
    }
    .team-social li {
      margin: 0;
    }
    .animated-circle{
      &:before {
        bottom: 20px;
      }
    }
    .play-button {
      img {
        bottom: 0;
      }
    }
  }
}

@media (max-width: 1669px) and (min-width: 1367px){
  .rtl {
    .feature-phone {
      top: 110px;
      right: 90px;
      img {
        width: 70%;
      }
    }
  }
}

@media (max-width: 1366px) {
  .rtl {
    .about {
      background-position: left;
    }
    .feature-phone {
      top: 126px;
      right:0;
    }
  }
}

@media (max-width: 991px) {
  .rtl {
    .testimonial-carousel-rtl, .price-carousel-rtl {
      &.owl-theme {
        .owl-dots {
          .owl-dot {
            &.active {
              span {
                background: #18e7d3;
                background: linear-gradient(to bottom, #18e7d3 0, #19d0de 100%);
                width: 25px;
                transition: all 0.3s ease;
              }
            }
          }
        }
      }
      &.owl-theme {
        .owl-dots {
          .owl-dot {
            span {
              background: #18e7d3;
              background: linear-gradient(to bottom, #18e7d3 0, #19d0de 100%);
              transition: all 0.3s ease;
            }
          }
        }
      }
    }
    .about-decor {
      .about-circle1 {
        right: unset;
        left: 50px;
        top: auto;
      }
    }
  }
}

@media (max-width: 768px) {
  .rtl {
    .home-two, .home-three {
      background-color: #19d8db;
    }
    .home-contain {
      text-align: center;
    }
  }
}