@import "variable";
html {
  overflow-x: hidden;
}
body {
  overflow-x: hidden;
  font-family: $font_0, $font_1;
  background-color: $white;
  position: relative;
  font-size: 15px;
  &.home-style-two {
    padding: 0 !important;
    &.modal-open {
      .navbar {
        padding: 15px 0 0 !important;
      }
    }
  }
  &.home-style {
    &.three {
      padding: 0 !important;
      &.modal-open {
        .navbar {
          padding: 15px 0 0 !important;
        }
      }
    }
  }
}
section {
  padding-top: 100px;
  padding-bottom: 100px;
}
h1 {
  line-height: 28px;
  font-size: 63px;
  margin-top: 25px;
  margin-bottom: 40px;
  color: $color_bright_turquoise;
  text-transform: inherit;
  font-weight: 300;
}

h2 {
  line-height: 28px;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 32px;
  color: $color_tundora;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.video-wrapper-header {
  h2 {
    line-height: 20px;
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 20px;
    color: #444444;
    text-transform: none;
    letter-spacing: 0px;
    justify-content: center;
    text-align: center;
  }
}

h3 {
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 30px;
  color: $color_tundora;
  text-transform: capitalize;
  letter-spacing: 1px;
}

h4 {
  line-height: 28px;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
}

h5 {
  line-height: 28px;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 15px;
  margin-bottom: 0;
  letter-spacing: 0.1em;
  color: $color_tapa;
}

h6 {
  line-height: 28px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-top: 25px;
  color: $color_tapa;
  margin-bottom: 25px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $color_turquoise;
}
p {
  line-height: 1.8em;
  font-size: 15px;
  color: $color_tapa;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  &.landing-caption {
    font-size: 30px;
    line-height: 1.5;
    span {
      color: $color_tundora;
      font-weight: 600;
    }
  }
}

a {
  &:hover {
    color: $white;
    text-decoration: none;
  }
  &:focus {
    color: $white;
    text-decoration: none;
  }
}

section {
  overflow: hidden;
}
.navbar-light {
  .default-nav {
    .navbar-nav {
      .nav-link {
        font-size: 15px;
        font-weight: 400;
        color: #777;
        text-transform: uppercase;
        letter-spacing: 0;
      }
    }
  }
}

@keyframes btn-animation {
  0% {
    transform: translateX(4px);
  }
  50% {
    transform: translateX(-4px);
  }
  100% {
    transform: translateX(0px);
  }
}

.vertical-scrolling {
  &.active {
    .price-block {
      animation: pulse 1s ease-in-out;
    }
    .contact-subscribe {
      animation: slideInLeft 1s ease-in-out;
    }
    .feature-style {
      li {
        &:nth-child(3) {
          margin-bottom: 0;
        }
      }
    }
    .bottom-section {
      .footer-style {
        animation: fadeIn 1.5s ease-in-out;
      }
    }
    .map {
      iframe {
        animation: fadeIn 1.5s ease-in-out;
      }
    }
  }
}
#map {
  height: 400px; /* The height is 400 pixels */
  width: 100%; /* The width is the width of the web page */
}

.home {
  //background-image: $url_2;
  width: 100vw;
  height: 100vh !important;
  background-repeat: no-repeat;
  margin-top: 0;
  display: block;
  background-size: cover;
  background-position: bottom;
  position: relative;
  .fp-tableCell {
    display: block;
  }
}
.gmap3 {
  margin: 20px auto;
  border: 1px dashed #c0c0c0;
  width: 1000px;
  height: 500px;
}

.overflow-hidden {
  overflow: hidden;
}

.blog-page {
  section {
    padding-bottom: 80px;
    padding-top: 80px;
  }
  .media {
    h5 {
      color: $color_tundora;
      font-weight: 600;
      text-transform: capitalize;
    }
    p {
      line-height: 24px;
      margin-bottom: 0;
      font-size: 14px;
    }
  }
}

.leave-coment {
  margin-top: 30px;
  h3 {
    font-size: 18px;
    text-transform: uppercase;
  }
}

.breadcrumb-bg {
  background-color: $color_black_haze;
  margin: 0 auto;
  padding-top: 15px;
  padding-bottom: 15px;
  h2 {
    margin: 0;
    padding: 0.75rem 0;
  }
}

.blog-bradcrumb {
  float: right;
  li {
    @extend %extend_1;
    a {
      @extend %extend_1;
    }
  }
}

.page-margin {
  margin-top: 125px;
}

ul {
  list-style-type: none;
}

.f-bold {
  font-weight: 700;
}

.f-color {
  color: $color_tundora;
}

.ml-10 {
  margin-left: 10px;
}

.btn {
  background-color: $color_bright_turquoise;
}
.theme-link {
  color: $color_scooter;
  transition: all 0.3s ease;
  &:hover,
  &:focus {
    color: $color_picton_blue;
    transition: all 0.3s ease;
  }
}
.form-group {
  position: relative;
  .show-hide {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    .show {
      &:after {
        content: "";
        width: 1px;
        height: 35px;
        background: #444;
        position: absolute;
        top: -11px;
        left: 5px;
        opacity: 1;
        transform: rotate(30deg);
      }
    }
    span {
      width: 12px;
      font-size: 0;
      height: 12px;
      line-height: 12px;
      background: $color_bright_turquoise;
      border-radius: 50%;
      float: right;
      cursor: pointer;
      &:before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #444444;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }
}
.btn-custom {
  color: $white;
  font-size: 14px;
  border-radius: 50px;
  text-transform: uppercase;
  padding: 10px 25px;
  cursor: pointer;
  border: none;
  background-size: 300% 100%;
  transition: all 0.4s ease-in-out;
  &.theme-color {
    background-image: linear-gradient(
      to right,
      #19d3dc,
      #18e7d3,
      #18e7d3,
      #19d2dd
    );
  }
  &:hover {
    background-position: 100% 0;
    transition: all 0.4s ease-in-out;
  }
  &:focus {
    outline: none;
  }
}
.btn-back:hover {
  i {
    animation: btn-animation 1s linear infinite;
    transform: scale(2);
  }
}
.tap-top {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 150px;
  right: 30px;
  z-index: 99;
  color: $white;
  text-align: center;
  background: linear-gradient(
    to top,
    $color_bright_turquoise,
    $color_turquoise
  );
  border-radius: 100%;
  font-size: 22px;
  cursor: pointer;
  line-height: 2.2;
  display: none;
  border: 1px solid $white;
}

.m-width {
  max-width: 1580px;
}

.back-img {
  background-image: $url_0;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.landing-circle {
  position: absolute;
  top: 20px;
  left: 20px;
}
.landing-circle1 {
  animation: movebounce 2.8s linear infinite;
  img {
    position: absolute;
    top: 204px;
    left: 260px;
  }
}

.landing-circle2 {
  animation: movebounce 3s linear infinite;
  img {
    position: absolute;
    top: 235px;
    left: 125px;
  }
}

.landing-circle3 {
  animation: movebounce 3.9s linear infinite;
  img {
    position: absolute;
    top: 102px;
    left: 124px;
  }
}

.pt-70 {
  padding-top: 70px;
}

.landing-title {
  font-weight: 700;
  color: $color_mine_shaft;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.border-shape {
  display: block;
  left: 0;
  margin: 15px auto;
  position: relative;
  right: 0;
  text-align: center;
  top: 0;
  width: 80px;
  height: 4px;
}

.demo-description {
  line-height: 26px;
  text-align: center;
}

.mt-35 {
  margin-top: 35px;
}

.pc-bg {
  background-image: $url_1;
  width: 515px;
  height: 290px;
  display: inline-block;
  position: relative;
  background-repeat: no-repeat;
  &:hover .img-scroll {
    background-position: center 100% !important;
  }
}

.img-scroll {
  transition: all 9s ease-out 0s;
  background-position: center 0;
  background-repeat: no-repeat;
  position: absolute;
  width: calc(100% - 50px);
  height: calc(100% - 47px);
  top: 15px;
  left: 25px;
  background-size: cover;
}

.home-demo-wrapper {
  align-items: center;
  justify-content: center;
}

.demo-title {
  font-size: 18px;
  letter-spacing: 1px;
  margin-top: 20px;
  margin-bottom: 0;
  color: $color_mine_shaft;
  font-weight: 700;
  line-height: 1;
}

.color-variation {
  margin-top: 10px;
  span {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
    display: inline-block;
    border-radius: 50%;
  }
}

.bg_grad {
  background: linear-gradient($color_bright_turquoise 0, $color_scooter 99%);
}

.bg_light-blue {
  background: linear-gradient($color_cyan_aqua 0, $color_picton_blue 99%);
}

.bg_green {
  background: linear-gradient(
    $color_spring_green 0,
    $color_caribbean_green 99%
  );
}

.border-shape-two {
  background-color: $color_alto;
  height: 1px;
  width: 100%;
  margin: 55px auto 10px;
  span {
    background-color: $color_alto;
    height: 7px;
    width: 100%;
    display: block;
    left: 0;
    margin: 4px auto;
    text-align: center;
  }
}

.features-bg {
  background-color: $color_gallery;
}

.margin-top-bottom {
  margin-top: 70px;
}

.features-title {
  font-weight: 700;
  color: $color_tapa;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 25px;
}

.features-img {
  width: 383px;
}

.template-bg {
  background: $color_mine_shaft;
}

.tapl-title {
  font-size: 36px;
  font-weight: 700;
  color: $white;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.rounded-5 {
  border-radius: 5px !important;
}
.tamp-d-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.rating-title {
  color: $color_mine_shaft;
  margin: 10px auto;
  letter-spacing: 1px;
  line-height: 1;
  font-size: 36px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.rating-star {
  margin-top: 0px;
  animation: fadeIn 1.5s ease-in-out;
  img {
    margin-right: 25px;
  }
}

.theme-nav {
  .navbar {
    background-color: transparent;
  }
  .nav-item {
    font-size: 14px;
    padding: 20px 0 20px 7px;
    color: $color_tapa;
    text-transform: uppercase;
    letter-spacing: 1px;
    .nav-link {
      transition: all 0.3s ease;
    }
    &:hover {
      .nav-link {
        color: $color_bright_turquoise;
        transition: all 0.3s ease;
      }
    }
    &.dropdown {
      .dropdown-menu {
        min-width: 200px;
        background: $white;
        display: none;
        left: 15px;
        right: 0;
        margin: 0;
        border: 0;
        opacity: 0;
        padding: 20px;
        visibility: hidden;
        border-radius: 0;
        border-radius: 3px;
        overflow: hidden;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.17);
        transition: all 0.6s ease-in;
        .nav-item {
          .nav-link {
            padding: 5px;
            font-size: 13px;
            text-transform: capitalize;
            letter-spacing: 0.3px;
            transition: all 0.3s ease;
            cursor: pointer;
            &.active {
              color: $color_bright_turquoise !important;
            }
            &:hover {
              color: $color_bright_turquoise !important;
              transition: all 0.3s ease;
            }
          }
        }
      }
      &:hover {
        .dropdown-menu {
          display: block;
          opacity: 1;
          visibility: visible;
        }
      }
    }
    &:last-child .dropdown-menu {
      right: 0;
      left: auto;
    }
    &:first-child .dropdown-menu {
      left: 15px;
    }
  }
  /*Dropdown css*/
  .dropdown-menu {
    .nav-item {
      font-size: 13px;
      padding: 5px 10px;
      padding: 0;
    }
    .nav-link {
      color: $color_tapa !important;
    }
  }
  &.darkHeader {
    padding-top: 0;
    padding-bottom: 0;
    .dropdown-menu .nav-item {
      padding: 0;
      .nav-link {
        &.active {
          color: #18e7d3;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
  .dropdown-menu {
    .nav-item {
      padding: 0;
    }
  }
  > li {
    &.dropdown {
      position: relative;
    }
  }
  &.darkHeader {
    .dropdown-menu {
      .nav-item {
        padding: 0;
      }
    }
  }
  /*dropdown css end*/
  .navbar-nav {
    .active {
      > .nav-link {
        color: $color_bright_turquoise;
        letter-spacing: 1px;
        cursor: pointer;
      }
    }
  }
}

.home-style-two,
.three {
  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: $white;
        opacity: 0.95;
        &.active {
          opacity: 1;
        }
      }
    }
  }
  .navbar-brand {
    filter: brightness(0) invert(1);
  }
  .darkHeader {
    .navbar-brand {
      filter: none;
    }
    &.navbar-light {
      .navbar-nav {
        .nav-link {
          color: $color_tundora;
        }
        .active {
          > .nav-link {
            color: $white;
          }
        }
      }
    }
  }
  .home-contain {
    color: $white;
  }
  &.navbar-light {
    .navbar-nav {
      .nav-link {
        &:focus {
          color: $white !important;
        }
      }
    }
  }
  .theme-nav {
    .navbar-nav {
      .active {
        > .nav-link {
          color: $white;
        }
      }
    }
  }
  .navbar-nav {
    .dropdown-menu {
      .nav-link:hover {
        color: $color_bright_turquoise !important;
      }
    }
  }
  .navbar {
    ul {
      &.navbar {
        li {
          &.nav-item {
            a {
              color: $white;
            }
          }
        }
      }
    }
  }
  .modal.video-play {
    //.modal-dialog {
    //  @extend %extend_3;
    //}
  }
}

.home-style {
  .navbar-brand {
    filter: brightness(0) invert(1);
  }
  nav {
    &.navbar {
      .navbar-nav {
        .nav-link {
          letter-spacing: 1px;
        }
        .active {
          > .nav-link {
            color: $white;
          }
        }
      }
    }
    &.darkHeader {
      .navbar-nav {
        .nav-link {
          color: $color_tundora;
          &.active {
            color: $color_bright_turquoise;
          }
        }
        .active {
          > .nav-link {
            color: $color_bright_turquoise;
          }
        }
      }
    }
  }
  .darkHeader {
    .navbar-brand {
      filter: none;
    }
  }
}

.fixed-top {
  padding-top: 15px;
  padding-bottom: 15px;
  transition: all 0.3s ease;
  box-shadow: 1px 1px 12px 1px rgba(221, 221, 221, 0.4);
}

.darkHeader {
  background: $white;
  box-shadow: 1px 1px 12px 1px rgba(221, 221, 221, 0.4);
  padding: 0;
  transition: all 0.3s ease;
  &.theme-nav .nav-item {
    padding: 20px 0 20px 7px;
  }
}

.store {
  margin-top: 10px;
}

.animated-circle {
  &.three {
    img {
      bottom: 180px;
      left: 70px;
    }
    &:before {
      bottom: 198px;
      left: 87px;
      animation: 2.5s anim-effect-front 0s linear infinite;
      box-shadow: 0 0 0 80px $color_gray_nurse;
    }
    &:after {
      bottom: 210px;
      left: 98px;
      animation: 2.5s anim-effect-back 0s linear infinite;
      box-shadow: 0 0 0 10px $wild_sand_100;
    }
  }
  &:before {
    animation: 2.5s anim-effect-front 0s linear infinite;
    box-shadow: 0 0 0 80px $color_gray_nurse;
    position: absolute;
    content: "";
    width: 60px;
    height: 60px;
    bottom: 188px;
    left: 97px;
    border-radius: 50%;
    z-index: 0;
  }
  &:after {
    animation: 2.5s anim-effect-back 0s linear infinite;
    box-shadow: 0 0 0 10px $wild_sand_100;
    position: absolute;
    content: "";
    width: 35px;
    height: 35px;
    bottom: 205px;
    left: 108px;
    border-radius: 50%;
    z-index: 0;
  }
}

.home-two {
  background-image: $url_3;
  background-position: bottom;
  .slide-cap-desc {
    color: $white;
  }
  .contain {
    position: absolute;
    top: calc(50% + 5px);
    left: calc(50% + 10px);
    margin-right: -50%;
    transform: translate(-50%, -100%);
  }
}

.home-three {
  background-image: $url_4;
  background-size: contain;
  background-position: top;
  .home-right {
    transform: translateY(175px);
  }
  .contain {
    position: absolute;
    top: calc(50% - 10px);
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -100%);
  }
}

.home-decor {
  .home-circle1 {
    position: absolute;
    top: 185px;
    left: 75px;
  }
  .home-circle2 {
    position: absolute;
    top: 180px;
    right: 188px;
  }
  .home-circle3 {
    position: absolute;
    top: 280px;
    right: 203px;
  }
}

.home-contain {
  display: flex;
  align-items: center;
  height: calc(100vh - 74px);
  margin-top: -27px;
  h4 {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.14em;
    font-size: 18px;
  }
}

.home-right {
  transform: translateY(90px);
  width: 100%;
  margin-top: -70px;
}

.slide-cap-desc {
  color: $white;
}

@-webkit-keyframes anim-effect-front {
  0% {
    box-shadow: 0 0 0 15px $color_bon_jour;
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 30px $color_bon_jour_60;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 40px rgba(224, 224, 224, 0);
    opacity: 0;
  }
}

@keyframes anim-effect-front {
  0% {
    box-shadow: 0 0 0 15px $color_bon_jour;
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 30px $color_bon_jour_60;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 40px rgba(224, 224, 224, 0);
    opacity: 0;
  }
}

@-webkit-keyframes anim-effect-back {
  0% {
    box-shadow: 0 0 0 30px $color_cararra;
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 50px $color_cararra_60;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 80px rgba(236, 236, 236, 0);
    opacity: 1;
  }
}

@keyframes anim-effect-back {
  0% {
    box-shadow: 0 0 0 20px $color_cararra_11;
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 50px $color_cararra_11;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 70px $color_cararra_11;
    opacity: 1;
  }
}

.about {
  width: 100vw;
  background-image: $url_5;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 0;
  position: relative;
  align-items: center;
}

.about-decor {
  .about-circle1 {
    position: absolute;
    top: auto;
    right: 140px;
    bottom: 170px;
  }
  .about-circle2 {
    position: absolute;
    top: auto;
    right: 5px;
    bottom: 20px;
  }
}

.about-contain {
  align-items: center;
  height: 100%;
  display: flex;
}

.about-right {
  background: $color_bright_turquoise;
  background: linear-gradient(
    to bottom,
    $color_bright_turquoise 0,
    $color_scooter 99%
  );
  border-radius: 100%;
  height: 600px;
  width: 600px;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-icon {
  position: absolute;
  left: 15px;
}

.icon-hover {
  border: 1px solid $color_mercury;
  padding: 10px;
  border-radius: 50%;
  transition: all 0.3s ease;
  width: 45px;
  height: 45px;
  text-align: center;
}

.about-style {
  margin-bottom: 20px;
  li {
    margin-bottom: 27px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.top-margin {
  margin-top: 7px;
}

.feature {
  background-image: $url_6;
  width: 100vw;
  min-height: 855px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0;
  margin-top: 0;
  position: relative;
  display: flex;
  align-items: center;
}

.feature-style {
  padding: 0;
  margin-bottom: 0;
  p {
    margin-top: 10px;
    margin-bottom: 0;
  }
  li {
    position: relative;
    margin-bottom: 45px;
  }
}

.feature-icon {
  position: absolute;
  padding: 0;
  top: 0;
}

.feature-phone {
  position: absolute;
  top: 100px;
  left: 325px;
  img {
    width: auto;
    height: 300px;
  }
}

.screenshot {
  background-image: $url_7;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 0;
  align-items: center;
  position: relative;
  background-position: center;
}

.screenshot-contain {
  display: flex;
  align-items: center;
  height: 630px;
}

.screenshot-decor {
  .screenshot-circle1 {
    position: absolute;
    top: 50px;
    right: 645px;
  }
  .screenshot-circle2 {
    position: absolute;
    top: 100px;
    right: 675px;
  }
  .screenshot-circle3 {
    position: absolute;
    top: auto;
    left: 35%;
    bottom: 0px;
  }
}

.mob-mocup {
  position: absolute;
  z-index: 2;
  width: 234px;
  left: 453px;
}

.mobile-light-left {
  position: absolute;
  z-index: 9;
  width: auto;
  left: 38.4%;
  bottom: 50px;
  animation: 2s ease-in 0s normal none infinite running highlight-left;
}

.mobile-light-right {
  position: absolute;
  z-index: 9;
  width: auto;
  right: 38%;
  top: 0px;
  animation: 2s ease-in 0s normal none infinite running highlight-right;
}

.owl-carousel {
  z-index: 1;
}

@-webkit-keyframes highlight-left {
  0% {
    opacity: 0;
    top: 320px;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 0;
  }
}

@keyframes highlight-left {
  0% {
    opacity: 0;
    top: 320px;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 0;
  }
}

@-webkit-keyframes highlight-right {
  0% {
    opacity: 0;
    bottom: 100px;
    transform: scale(0);
  }
  70% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    top: 280px;
    transform: scale(0);
  }
}

@keyframes highlight-right {
  0% {
    opacity: 0;
    bottom: 100px;
    transform: scale(0);
  }
  70% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    top: 400px;
    transform: scale(0);
  }
}

.team {
  background-image: $url_8;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: 0 10%;
  .team-circle1 {
    position: absolute;
    top: auto;
    left: 100px;
    bottom: 300px;
  }
  .team-circle2 {
    position: absolute;
    top: auto;
    left: 180px;
    bottom: 180px;
  }
  .team-circle3 {
    position: absolute;
    top: auto;
    left: 0;
    bottom: -150px;
  }
}

.team-block {
  background-image: $url_9;
  width: 100%;
  height: auto;
  padding: 50px 30px;
  .team-social {
    left: 0;
    right: 0;
    text-align: center;
    li a {
      border: 1px solid #ddd;
      border-radius: 100%;
      padding: 8px;
      text-align: center;
      color: $color_turquoise_blue;
      transition: all 0.3s ease;
      i {
        width: 20px;
        font-size: 14px;
        color: #444444;
      }
      &:hover {
        background-color: $color_turquoise_blue;
        border: 1px solid $color_turquoise_blue;
        transition: all 0.3s ease;
      }
    }
  }
}

.team-box {
  background-color: $white;
  padding: 30px;
  .overlay {
    position: relative;
  }
  .team-avtar {
    img {
      height: 170px;
      width: 170px !important;
      border-radius: 100%;
      margin: 0 auto;
    }
  }
}

.team-text {
  h3 {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.1em;
    margin-top: 30px;
    margin-bottom: 13px;
    line-height: 1;
  }
  h6 {
    margin-top: 0;
    margin-bottom: 15px;
    text-align: center;
    line-height: 1;
  }
}

.team-social {
  position: absolute;
  padding-left: 0;
  align-items: center;
  margin: 0;
  transition: 0.3s ease-in-out;
  padding-bottom: 0;
  > li {
    margin: 0 2px;
    display: inline-block;
  }
  li {
    a {
      &:hover {
        i {
          color: $white;
        }
      }
    }
  }
}

.team-carousel {
  margin-top: 12px;
  .owl-dots {
    position: absolute;
    top: -80px;
    right: 0;
  }
  &.owl-theme .owl-dots .owl-dot {
    span {
      border: 1px solid $white;
      background: $color_bright_turquoise;
      background: linear-gradient(
        to bottom,
        $color_bright_turquoise 0,
        $color_scooter 100%
      );
      transition: all 0.3s ease;
    }
    &.active span {
      background: $color_bright_turquoise;
      background: linear-gradient(
        to bottom,
        $color_bright_turquoise 0,
        $color_scooter 100%
      );
      width: 25px;
      transition: all 0.3s ease;
    }
  }
  button {
    &:focus {
      outline: 0;
    }
  }
}

.blog-carousel {
  margin-top: 12px;
  .owl-dots {
    position: absolute;
    top: -80px;
    right: 0;
  }
  &.owl-theme .owl-dots .owl-dot {
    span {
      background: $color_bright_turquoise;
      background: linear-gradient(
        to bottom,
        $color_bright_turquoise 0,
        $color_scooter 100%
      );
      transition: all 0.3s ease;
    }
    &.active span {
      background: $color_bright_turquoise;
      background: linear-gradient(
        to bottom,
        $color_bright_turquoise 0,
        $color_scooter 100%
      );
      width: 25px;
      transition: all 0.3s ease;
    }
  }
  button:focus {
    outline: 0;
  }
}

.price-carousel {
  .price-item {
    margin: 3px;
  }
  &.owl-theme .owl-dots .owl-dot {
    span {
      background: $color_bright_turquoise;
      background: linear-gradient(
        to bottom,
        $color_bright_turquoise 0,
        $color_scooter 100%
      );
      transition: all 0.3s ease;
    }
    &.active span {
      background: $color_bright_turquoise;
      background: linear-gradient(
        to bottom,
        $color_bright_turquoise 0,
        $color_scooter 100%
      );
      width: 25px;
      transition: all 0.3s ease;
    }
  }
}

.testimonial-carousel {
  &.owl-theme {
    .owl-dots .owl-dot.active span {
      background: $color_bright_turquoise;
      background: linear-gradient(
        to bottom,
        $color_bright_turquoise 0,
        $color_scooter 100%
      );
      width: 25px;
      transition: all 0.3s ease;
    }
    .owl-nav [class*="owl-"]:hover {
      background: none;
    }
  }
  &.owl-carousel button {
    &.owl-prev {
      position: absolute;
      height: 100%;
      top: 0;
      display: flex;
      align-items: center;
      left: -170px;
    }
    &.owl-next {
      position: absolute;
      height: 100%;
      top: 0;
      display: flex;
      align-items: center;
      right: -170px;
    }
  }
  button:focus {
    outline: 0;
  }
}

.overlay {
  padding-top: 15px;
  padding-bottom: 30px;
}

.d-align-center {
  align-items: center;
}

.blog {
  width: 100vw;
  margin-top: 0;
  align-items: center;
}

.blog-block {
  background-image: $url_9;
  width: 100%;
  height: auto;
  padding: 15px;
  &:hover img {
    transform: rotate(3deg) scale(1.15);
    transition: all 0.3s ease;
  }
}

.blog-box {
  padding: 5px;
  background-color: $white;
  img {
    opacity: 1;
    transition: 0.3s ease-in-out;
    overflow: hidden;
  }
}

.blog-text {
  h3 {
    margin-top: 15px;
    line-height: 24px;
    font-size: 17px;
    margin-bottom: 15px;
  }
  p {
    font-size: 14px;
    margin-top: 15px;
  }
  h5 {
    margin: 0;
    line-height: 1;
    text-transform: capitalize;
    display: inline-block;
    font-weight: 600;
  }
  h6 {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.blog-pagin {
  margin: 0;
  .page-item {
    &:first-child a.page-link {
      border-top-left-radius: unset !important;
      border-bottom-left-radius: unset !important;
    }
    &:last-child .page-link {
      border-top-right-radius: unset !important;
      border-bottom-right-radius: unset !important;
    }
  }
  a.page-link {
    color: $color_bright_turquoise;
    font-size: 16px;
    border: 1px solid $color_gallery;
    transition: all 0.3s ease;
    &:hover {
      text-decoration: none;
      background-color: $color_bright_turquoise;
      border-color: $color_bright_turquoise;
      color: $white !important;
      transition: all 0.3s ease;
    }
  }
}

.blog-pagination {
  margin-top: 50px;
  li.page-item {
    border-radius: unset;
  }
}
.sidebar,
.reply-comment {
  img {
    border-radius: 5px;
  }
}
.sidebar-space {
  margin-bottom: 50px;
}

.blog-title {
  text-transform: uppercase;
  font-size: 18px;
  color: $color_tundora;
  letter-spacing: 1px;
}

.blog-divider {
  height: 1px;
  background-color: $gray;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
}

.blog-description {
  margin: 20px auto;
}

.blog-cat-detail {
  ul {
    padding: 0;
    margin: 0;
    li {
      a {
        color: $color_tapa;
        text-transform: capitalize;
        font-size: 14px;
        text-decoration: none;
        transition: 0.3s ease;
        font-weight: 500;
        &:hover {
          color: $color_bright_turquoise;
          transition: 0.3s ease;
        }
      }
      i {
        margin-right: 15px;
      }
    }
  }
  .marg-15 {
    margin: 15px auto;
  }
}

.marg-20 {
  margin: 20px auto;
}

.reply-comment {
  margin: 30px auto;
  > div + div {
    margin-top: 20px;
  }
}

.vids {
  background-image: $url_2;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0;
  margin-top: 0;
  position: relative;
  display: flex;
  align-items: center;
}

.price {
  background-image: $url_10;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  margin-top: 0;
  align-items: center;
}

.price-block {
  border-radius: 15px;
  background-color: $white;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
  box-shadow: 0 5px 27px 0 $black_5;
  transition: all 200ms ease;
  //transition: 0.3s ease;
  &:hover {
    .mrp {
      h2 {
        color: $color_bright_turquoise;
        transition: all 0.3s ease;
      }
    }
  }
  &:hover {
    transform: translateY(-12px);
  }

  .btn-custom {
    width: 100%;
    padding: 15px 25px;
    font-weight: 700;
    letter-spacing: 2px;
  }
}

.price-type {
  //background-image: $url_11;
  padding: 20px;
  background-size: cover;
  h2 {
    background-color: $white;
    padding: 10px;
    text-align: center;
    color: $color_bright_turquoise;
    margin-bottom: 0;
    font-weight: 700;
    letter-spacing: 0.1em;
    font-size: 24px;
  }
}

.mrp {
  h2 {
    font-size: 36px;
    letter-spacing: 0.1em;
    font-weight: bold;
    margin-bottom: 0;
    line-height: 1;
    margin-top: 30px;
    color: $color_mine_shaft;
    transition: all 0.3s ease;
  }
  h6 {
    &.user-type {
      font-weight: 400;
      color: $color_tundora;
      margin-bottom: 25px;
      line-height: 1;
    }
  }
}

.price-year {
  font-weight: 700;
  margin: 5px;
  margin-bottom: 30px;
  line-height: 1;
}

.price-devide {
  height: 1px;
  background-color: $gray;
  width: 245px;
  margin: 0 auto;
}

.price-feature {
  padding: 0;
  margin: 25px auto;
  li {
    padding: 10px;
    text-transform: capitalize;
    color: #777;
  }
}

.testimonial {
  background-image: $url_12;
  background-size: contain;
  position: relative;
  width: 100vw;
  margin-top: 0;
  align-content: center;
  padding: 0;
}

.testimonial-decor {
  .testi-circle1 {
    position: absolute;
    left: 247px;
    bottom: 160px;
  }
  .testi-circle2 {
    position: absolute;
    left: 50px;
    top: auto;
    bottom: 0px;
  }
}

.testimonial-block {
  position: relative;
  padding: 80px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 0;
}

.testimonial-avtar {
  background-image: $url_13;
  border-radius: 100%;
  height: 120px;
  width: 120px;
  padding: 15px;
  text-align: center;
  margin: 0 auto;
  background-size: cover;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  img {
    border-radius: 100%;
    height: 90px;
  }
  &:before {
    animation: 2.5s anim-effct-testi 0s linear infinite;
    box-shadow: 0 0 0 80px $color_gray_nurse;
    position: absolute;
    content: "";
    width: 120px;
    height: 120px;
    top: 0;
    left: 0;
    border-radius: 50%;
    z-index: 0;
  }
  &:after {
    animation: 2.5s anim-effct-testi-back 0s linear infinite;
    box-shadow: 0 0 0 10px $wild_sand_100;
    position: absolute;
    content: "";
    width: 120px;
    height: 120px;
    top: 0;
    left: 0;
    border-radius: 50%;
    z-index: 0;
  }
}

.testimonial-text {
  text-align: center;
  box-shadow: 0 0 65px 0 $color_silver_chalice_25;
  background-color: $white;
  padding-top: 100px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  margin-bottom: 100px;
  h3 {
    color: $color_bright_turquoise;
    margin-bottom: 0;
  }
  h6 {
    text-transform: capitalize;
    margin: 0;
  }
  p {
    letter-spacing: 0;
  }
}

@-webkit-keyframes anim-effct-testi {
  0% {
    box-shadow: 0 0 0 8px $color_bon_jour;
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 15px $color_bon_jour_60;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
    opacity: 0;
  }
}

@keyframes anim-effct-testi {
  0% {
    box-shadow: 0 0 0 8px $color_bon_jour;
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 15px $color_bon_jour_60;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
    opacity: 0;
  }
}

@-webkit-keyframes anim-effct-testi-back {
  0% {
    box-shadow: 0 0 0 8px $color_cararra_11;
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 15px $color_cararra_11;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 25px $color_cararra_11;
    opacity: 1;
  }
}

@keyframes anim-effct-testi-back {
  0% {
    box-shadow: 0 0 0 8px $color_cararra_11;
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 15px $color_cararra_11;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 25px $color_cararra_11;
    opacity: 1;
  }
}

.contact {
  background-image: $url_14;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  .contact-us {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.contact-decor {
  .contact-circle1 {
    position: absolute;
    bottom: 210px;
    left: 280px;
  }
  .contact-circle2 {
    position: absolute;
    bottom: 20px;
    left: 175px;
  }
}

.theme-form {
  margin-top: 45px;
  .form-control {
    font-size: 12px;
    border: none;
    border-radius: 20px;
    padding: 12px 30px;
    box-shadow: 0 0 5px 0 $color_silver_chalice_15;
  }
  .btn-custom {
    padding: 10px 40px;
  }
  input::-webkit-input-placeholder {
    color: $color_silver_chalice !important;
  }
  textarea::-webkit-input-placeholder {
    color: $color_silver_chalice !important;
  }
  .form-button {
    text-align: right;
  }
  .form-group {
    margin: 25px auto;
  }
}

.footer-text {
  text-align: center;
  h2 {
    margin: 25px auto;
  }
  p {
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 25px;
  }
  .footer-form {
    padding: 0;
    .form-control {
      border-radius: 50px;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: 25px;
    }
  }
}
.footer-form {
  padding-left: 50px;
  padding-right: 50px;
  .form-group {
    margin-bottom: 20px;
  }
  .btn-custom {
    padding: 10px 40px;
  }
  .form-button {
    text-align: center;
  }
  .form-control {
    border-radius: 35px;
    text-transform: uppercase;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 2px solid $color_gallery;
    font-size: 12px;
    text-align: center;
  }
  input::-webkit-input-placeholder {
    color: $color_silver_chalice !important;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.1em;
  }
}

.address-bar {
  background: linear-gradient(
    to top,
    $color_scooter 0,
    $color_bright_turquoise 100%
  );
  padding-top: 100px;
  padding-bottom: 100px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-icon {
  position: absolute;
  top: -3px;
}

.footer-style {
  padding: 0;
  margin: 0;
  li {
    position: relative;
    & + li {
      margin-top: 50px;
    }
    &:nth-last-child(1) .footer-icon {
      margin-bottom: 0;
    }
  }
}

.loader-wrapper {
  height: 100vh;
  width: 100vw;
  background-color: $white;
  display: block;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1031;
  top: 0;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background-color: $color_turquoise_blue;
  border-radius: 50%;
  &:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    border: 0 solid $white;
    transform: translate(-50%, -50%);
    animation: loading 1000ms ease-out forwards infinite;
    transition: all 0.3s ease;
  }
}

@keyframes loading {
  0% {
    border: 0 solid $white;
    transition: all 0.3s ease;
  }
  20% {
    border: 8px solid $white;
    width: 0;
    height: 0;
    transition: all 0.3s ease;
  }
  100% {
    border: 8px solid $white;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
  }
}

.fadin-blog {
  animation: fadeIn 1.5s ease-in-out;
}

.fade-button {
  animation: fadeIn 1.5s ease-in-out;
}

.fadinup {
  animation: fadeIn 1.5s ease-in-out;
}

.home-circle3 {
  animation: movebounce 3.9s linear infinite;
}

.about-circle2 {
  animation: movebounce 3.9s linear infinite;
}

.screenshot-circle3 {
  animation: moveleftbounce 3.9s linear infinite;
}

.team-circle3 {
  animation: movebounce 3.9s linear infinite;
}

.contact-circle2 {
  animation: moveleftbounce 3.9s linear infinite;
}

.home-circle1 {
  animation: movebounce 3s linear infinite;
}

.about-circle1 {
  animation: movebounce 3s linear infinite;
}

.feature-circle1 {
  animation: movebounce 3s linear infinite;
}

.screenshot-circle1 {
  animation: movebounce 3s linear infinite;
}

.team-circle2 {
  animation: movebounce 3s linear infinite;
}

.testi-circle2 {
  animation: movebounce 3s linear infinite;
}

.contact-circle1 {
  animation: moveleftbounce 3s linear infinite;
}

.home-circle2 {
  animation: movebounce 2.8s linear infinite;
}

.screenshot-circle2 {
  animation: movebounce 2.8s linear infinite;
}

.team-circle1 {
  animation: movebounce 2.8s linear infinite;
}

.testi-circle1 {
  animation: movebounce 2.8s linear infinite;
}

@keyframes movebounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes moveleftbounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}

.color-1 {
  background: $color_bright_turquoise;
  background: linear-gradient(to right, $color_bright_turquoise, $color_java);
}

.color-2 {
  background: linear-gradient(
    to right,
    $color_cyan_aqua 0,
    $color_picton_blue 99%
  );
}

.color-3 {
  background: $color_caribbean_green;
}

.color-picker {
  position: fixed;
  right: -190px;
  top: 200px;
  width: 190px;
  z-index: 9;
  transition: all 0.3s ease;
  .settings-header {
    background: linear-gradient(
      $color_bright_turquoise,
      $color_bright_turquoise
    );
    padding: 5px 15px;
    h3 {
      color: $white;
      margin: 0;
      font-size: 20px;
      padding: 4px 9px;
    }
  }
  .section {
    background: $white;
    padding: 20px;
    border: 1px solid $color_bright_turquoise;
    overflow: auto;
    i {
      font-size: 16px;
      margin-right: 10px;
    }
  }
  a.handle {
    position: absolute;
    right: 190px;
    width: 50px;
    height: 49px;
    color: $white;
    transition: all ease-in 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    z-index: -1;
    border-radius: 50%;
    text-decoration: none;
    background: $color_bright_turquoise;
    top: -80px;
    border: 1px solid $white;
  }
  .colors a {
    width: 35px;
    height: 35px;
    margin: 0 7px;
    float: left;
    transition: all ease-in 0.3s;
  }
  .skin a {
    display: block;
    width: 70px;
    height: 30px;
    margin-left: 0;
    margin-top: 0;
    margin-right: 5px;
    float: left;
    text-align: center;
    line-height: 30px;
    border: 2px transparent solid;
    &.actt {
      border: 2px $white solid;
    }
  }
}

.handle {
  i {
    animation: ring-rotate 3s infinite linear;
  }
  &:hover {
    background-color: $white;
  }
}

@keyframes ring-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sec-position {
  position: relative;
  top: -80px;
}

.title span {
  font-weight: 700;
}
img.main-logo {
  margin-bottom: 45px;
}

.pages-space > div + div + div + div {
  margin-top: 50px;
}

.features li {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  text-align: center;
  border-right: 1px solid $color_alto;
  border-bottom: 1px solid $color_alto;
  &:nth-child(4) {
    border-right: none;
  }
  &:nth-child(8) {
    border-right: none;
  }
  &:nth-child(12) {
    border-right: none;
  }
  &:nth-child(13) {
    border-bottom: none;
  }
  &:nth-child(14) {
    border-bottom: none;
  }
  &:nth-child(15) {
    border-bottom: none;
  }
  &:nth-child(16) {
    border-bottom: none;
  }
  &:last-child {
    border-right: none;
  }
  img {
    transition: all 0.9s ease-out;
  }
  h3 {
    transition: all 0.9s ease-out;
  }
  &:hover {
    img {
      box-shadow: 0 0 35px #33333326;
      transition: all 0.3s ease-in-out;
    }
    h3 {
      color: $color_mine_shaft;
      transition: all 0.3s ease;
    }
  }
}

.garb-img ul li {
  display: inline-block;
}

.navbar-nav > li.dropdown {
  position: relative;
}

.navbar-light {
  .default-nav {
    .navbar-nav {
      .nav-link {
        &.active {
          color: $color_bright_turquoise;
        }
      }
    }
  }
  .navbar-nav {
    > .nav-link {
      color: $color_tundora;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      &.active {
        color: $white;
      }
    }
  }
  &.darkHeader {
    .navbar-nav {
      .nav-link {
        &.active {
          color: $color_bright_turquoise;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}
.home-style-two,
.three {
  .navbar-light {
    .default-nav {
      .navbar-nav {
        .nav-link {
          &.active {
            color: $white;
          }
        }
      }
    }
    &.darkHeader {
      .default-nav {
        .navbar-nav {
          .nav-link {
            &.active {
              color: $color_bright_turquoise;
            }
          }
        }
      }
    }
  }
}
.three .navbar-nav .dropdown-menu .nav-link:hover {
  color: $color_bright_turquoise !important;
}

.video-play .modal-content {
  background-color: transparent;
  border: none;
}

iframe.video-play {
  width: 1200px;
  height: 700px;
  margin: 0 auto;
  border: none;
}

.play-button img {
  position: absolute;
  bottom: 170px;
  left: 80px;
  z-index: 9;
}

.abt-hover:hover {
  .icon-hover {
    background: linear-gradient(
      to bottom,
      $color_bright_turquoise 0,
      $color_scooter 100%
    );
    transition: all 0.2s ease;
  }
  img {
    filter: brightness(0) invert(1);
    transition: all 0.2s ease;
  }
}

.about-text {
  h3 {
    padding-left: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin: 0;
  }
}

.feature-decor .feature-circle1 {
  position: absolute;
  top: auto;
  right: 100px;
  bottom: 100px;
}

.feature-subtitle h3 {
  padding-left: 35px;
  margin-top: 8px;
}

.screenshot-carousel .owl-item {
  &.active {
    filter: grayscale(100%);
    &.center {
      opacity: 1;
      filter: grayscale(0%);
    }
  }
  &.cloned {
    filter: grayscale(100%);
  }
}

.blog-list > div + div + div .blog-item {
  margin-top: 50px;
}

.blog-detail .blog-text h3 {
  font-size: 18px;
}

.page-item.active .page-link {
  color: $white;
  background-color: $color_bright_turquoise;
  border-color: transparent;
  border: 1px solid $color_bright_turquoise;
}

.tags .badge-theme {
  padding: 5px 7px;
  font-size: 12px;
  background-color: $color_bright_turquoise;
  margin-right: 2px;
  margin-bottom: 5px;
  color: $white;
  font-weight: 400;
  transition: 0.3s ease;
  &:hover {
    background-color: $color_tundora;
    transition: 0.3s ease;
  }
}

.recent-blog {
  .media {
    margin-bottom: 15px;
    align-items: center;
    h5 {
      line-height: 24px;
      font-size: 14px;
      font-weight: 600;
      color: $color_tundora;
      text-transform: capitalize;
      letter-spacing: 1px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 50%;
    }
    p {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      color: $color_tapa;
    }
  }
}
.bottom-section {
  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
}
.footer-address {
  a {
    padding-left: 35px;
    color: $white !important;
    letter-spacing: 0.03em;
    line-height: 22px;
    margin-bottom: 0;
    display: block;
  }
}
.copyright-section {
  p {
    text-align: center;
    width: 100%;
    padding: 15px;
    margin-bottom: 0;
  }
}

/*=======================
  18. Sign In
=========================*/
.social-btns {
  .btn {
    margin: 0 10px;
  }
  .fb {
    background-color: #3b5998;
    background-image: linear-gradient(
      to right,
      #3b5998,
      #3c769c,
      #3b5b98,
      #3c749c
    );
  }
  .ggl {
    background-color: #d85040;
    background-image: linear-gradient(
      to right,
      #d85040,
      #ff6e63,
      #d85040,
      #f9695d
    );
  }
}
.authentication-form {
  .or-saparator {
    position: relative;
    padding: 10px 0;
    text-align: center;
    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      height: 1px;
      margin-top: 1px;
      width: 100%;
      content: "";
      background: rgba(40, 56, 76, 0.1);
      z-index: 1;
    }
    span {
      position: relative;
      display: inline-block;
      text-transform: uppercase;
      font-size: 0.81em;
      padding: 0 8px;
      background: #fff;
      color: #868686;
      z-index: 2;
    }
  }
  .card {
    border: 0;
    padding: 30px 50px 50px;
    width: 500px;
    margin: 0 auto;
    border-top: 3px solid #1ad7dd;
    border-radius: 0;
  }
  .title {
    margin-bottom: 20px;
  }
  p {
    width: 70%;
    margin: 0 auto 30px;
  }
  .theme-form {
    margin-top: 0;
    .form-control {
      box-shadow: 0 0 5px 0 rgba(170, 170, 170, 0.35);
    }
  }
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
  background-image: url(../images/auth-bg.jpg);
  background-repeat: no-repeat;
  .innerpage-decor {
    .innerpage-circle1 {
      bottom: 180px;
    }
    .innerpage-circle2 {
      bottom: 20px;
    }
  }
}

/*======================
  19. Thank You Page
========================*/
/*=======================
  20. 404 Page
=========================*/
.error-font {
  font-size: 230px;
  line-height: 1;
  font-weight: 900;
  color: $color_bright_turquoise;
  letter-spacing: -32px;
  span {
    display: inline-block;
    color: #444;
  }
}
.sub-content {
  font-weight: 600;
}
/*=======================
  21. Review Page 
=========================*/
.innerpage-decor {
  .innerpage-circle1 {
    position: absolute;
    left: 247px;
    bottom: 250px;
    animation: movebounce 2s linear infinite;
  }
  .innerpage-circle2 {
    position: absolute;
    left: 50px;
    top: auto;
    bottom: 80px;
    animation: movebounce 4s linear infinite;
  }
}
.margin-bottom > div + div + div + div .review-box {
  margin-top: 30px;
}
.review-box {
  box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  background-color: $white;
  img {
    height: 60px;
    border-radius: 100%;
  }
  h5 {
    color: $color_scooter;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 5px;
    text-transform: capitalize;
    font-weight: 600;
  }
  p {
    font-size: 14px;
    margin-top: 10px;
  }
  i {
    color: #eeb829;
  }
}
/*========================
 22. Faq Page
==========================*/
.faq {
  .card {
    border-radius: 25px;
    border-color: #f1f1f1;
  }
  a {
    i {
      float: right;
      transition: all 0.3s ease;
      padding-top: 3px;
      font-size: 20px;
    }
  }
  .card-body {
    line-height: 1.7;
  }
  .card-header {
    background-color: transparent;
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;
    padding: 15px 35px;
    border-radius: 25px;
    a {
      font-size: 16px;
      font-weight: 600;
      color: #797979;
    }
    &[aria-expanded="true"] {
      a {
        color: $color_turquoise;
        i {
          &:before {
            content: "\f106";
          }
        }
      }
    }
  }
}
.thanks-section {
  img {
    margin-bottom: 15px;
  }
  h2 {
    font-weight: 600;
  }
  h3 {
    font-weight: 400;
  }
}
.full-page {
  max-height: 100vh;
  overflow: hidden;
  height: 100vh;
  background-color: #e9eff0;
}
.download {
  .downlod-img {
    margin-bottom: 50px;
  }
  h2 {
    font-weight: 600;
    line-height: 1.5;
  }
  h3 {
    font-weight: 400;
  }
}
.manual-down {
  color: #00bac3;
  text-decoration: underline;
  &:hover {
    color: #444;
    text-decoration: underline;
  }
}
.footer-bg {
  background-color: $white;
}

.login-form {
  min-height: 770px;
  display: flex;
  align-items: center;
  padding-bottom: 0;
}
.main {
  background-color: $white;
  box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  .custom-select.is-valid {
    border-color: $border_gray;
  }
  .custom-select.is-invalid {
    border-color: $border_gray;
  }
}
.form-control.is-valid {
  border-color: $border_gray;
}
.was-validated {
  .custom-select {
    &:valid {
      border-color: $border_gray;
    }
    &:invalid {
      border-color: $border_gray;
    }
  }
  .form-control {
    &:valid {
      border-color: $border_gray;
    }
    &:invalid {
      border-color: $border_gray;
    }
  }
}
.form-control.is-invalid {
  border-color: $border_gray;
}
.thanks-bg {
  background-color: #e9eff0;
  text-align: center;
}
.bg-thanks {
  background: #e9eff0;
  p {
    color: #444;
  }
}

/*23. Coming soon */
.clock-box {
  h3 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  li {
    display: inline-block;
    width: 100px;
    margin: 0px 10px 20px;
    span {
      display: block;
      margin-bottom: 10px;
      font-size: 44px;
      border-radius: 100%;
      width: 100px;
      height: 100px;
      line-height: 2.3;
      background-image: linear-gradient(to right, #19d3dc, #18e7d3, #18e7d3);
    }
  }
}
.coming-soon {
  position: relative;
  .logo {
    height: 50px;
  }
}

//custom
.p-hero {
  //font-family: sofia-pro, sans-serif;
  color: #666;
  font-size: 24px;
  line-height: 35px;
  text-align: left;
  text-decoration: none;
}

.button.hero {
  padding: 12px 18px;
  border-radius: 5px;
  background-color: #6cd5e1;
  font-size: 18px;
}

.w-button-vids {
  display: inline-block;
  padding: 9px 15px;
  background-color: #fafafa;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
  
}

.btnVids {
  padding: 12px 18px;
  border-radius: 5px;
  font-size: 20px;
  border-style: solid;
  border-color: #53dbdb;
  border-width: 0.15em;
  display: inline-block;
  transition: all 0.3s ease 0s;
  text-align: center;
  font-weight: 500;
  font-style: bold;
}

.btnVids:hover {
  color: #404040 !important;
  font-weight: 700 !important;
  letter-spacing: 3px;
  background: none;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
   transition: all 0.3s ease 0s;
  //transition: cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }

.button.hero.secondary {
  margin-left: 20px;
  background-color: #e7e7e7;
  color: #777;
}

.w-button {
  display: inline-block;
  padding: 9px 15px;
  background-color: #3898ec;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}

._40px-divider {
  height: 40px;
}

.w-col {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}

.w-col-6 {
  width: 50%;
}

.divHero{
  width: 35%;
  display: flex; 
  justify-content: space-between;
  text-align: center;
  margin-left: 13%;
}

.w-vids {
  width: 50%;
        margin: 0 auto;
        padding: 20px;
}


.w-col-7 {
  width: 100%;
}

.column-36 {
  text-align: left;
}
//heading title
.h1-hero {
  font-size: 50px;
  line-height: 65px;
  text-align: left;
}

.hero-section {
  position: static;
  top: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding-top: 80px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

._20px-divider {
  display: block;
  height: 20px;
}

.home-all-products {
  margin-right: auto;
  margin-left: auto;
  padding: 80px 15px;
  background-color: #f6f7fa;
}

.home-grid-container {
  width: 1200px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.pack-card-home {
  width: 100%;
  padding: 40px 20px;
  border-radius: 15px;
  background-color: #fff;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #333;
  text-decoration: none;
}

.video-wrapper {
  position: relative;
  padding-bottom: 30%; /*56.25%;*/ /* 16:9 */
  padding-top: 15px;
  height: 0;
}

.video-wrapper > video {
  //position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.example_e {
  border: none;
  //background: #3898EC;
  color: #404040 !important;
  font-weight: 700;
  padding: 20px;
  text-transform: uppercase;
  border-radius: 6px;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

.example_e:hover {
  color: #404040 !important;
  font-weight: 700 !important;
  letter-spacing: 3px;
  background: none;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.3s ease 0s;
}
